import { IUser } from "@chronos/api";
import { makeAutoObservable } from "mobx";
import api from "../helpers/api";

interface IProfile extends IUser {
  originalDate?: string;
}

export class Profile {
  constructor() {
    makeAutoObservable(this)
  }

  profile: IProfile | null = null

  async getProfile() {
    try {
      const result = await api.profile()
      this.profile = result
    } catch(e) {
      console.error(`Getting profile error: ${e}`)
    }
  }

  async updateProfile(profile: IProfile) {
    try {
      await api.updateProfile(profile)
    } catch(e) {
      console.error(`Updating profile error: ${e}`)
    }
  }
}
