import React from 'react';
import styled from 'styled-components';
import BannerAutumn from '../../../assets/images/banner_autumn.jpg';
import { gtmSend } from '../../../helpers/url';
import store from '../../../store/Store';

export const MainPageAutumnBanner = () => {
    const userId = store.sessionData?.id;

    const onClick = () => {
        gtmSend({'event': `indi_banner_veb_osenravn`, 'user_id': userId ? userId : ''})
        setTimeout(() => {
            window.location.href = 'https://p.chronos.ru/transition-point?utm_source=chronos_plus&utm_medium=webinar&utm_campaign=120924&utm_content=banner ';
        }, 500)
        
    }
    return <Container onClick={onClick}>
        <img src={BannerAutumn} alt={"День осеннего равноденствия"} />
    </Container>
}

const Container = styled.div`
    position: relative;
    box-sizing: border-box;
    margin: 1rem 1rem 0;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;

    img {
        width: 100%;
        display: block;
    }
`
