import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PersonalForm, { IPersonalForm } from "../../../../components/forms/PersonalForm";
import { gtmSend, searchParams } from '../../../../helpers/url';
import store from '../../../../store/Store';
import { getPartnerLS, setProductDataLS, setProductTokenLS } from "../../../../store/localStorageHelpers";
import { EMOTIONS, SOLAR, SYNASTRY, productsWindowData } from '../../helpers/constants';
import { windowsStore } from '../../../../store/Windows';
import SmsAuth, { WINDOW_ID_SMS_AUTH, IAuthSmsProps } from '../../../registration/sms/index';
import { Button } from '../../../../libs';


interface IPersonalData {
  product: string
  step: number
  edit: boolean
  lastStep: number
  setStep(value: number): void
  setEdit(value: boolean): void
}

interface ITitles {
  SYNASTRY: {new: string, edit: string}
  EMOTIONS: {new: string, edit: string}
  SOLAR: {new: string, edit: string}
}

export const openSmsRegistrationWindow = ({ title, subtitle, onlyPhoneNumberConfirm, onSuccess }: IAuthSmsProps) => {
  windowsStore.open(
    WINDOW_ID_SMS_AUTH,
    <SmsAuth onSuccess={onSuccess} title={title} subtitle={subtitle} onlyPhoneNumberConfirm={onlyPhoneNumberConfirm} />,
    'fade'
  )
}

export default observer(function Form(props: IPersonalData): JSX.Element {
  const [formData, setFormData] = useState<IPersonalForm>({ name: '', dateTime: dayjs().add(dayjs().utcOffset(), 'minute').toISOString(), place: null });
  const [loading, setLoading] = useState(false)

  const profile = store.profile
  const isAuth = store.isAuth
  const userId = store.sessionData?.id
  const hasProfileData = isAuth && profile.firstName && profile.birth.dateTime && profile.birth.place
  const isNeedAuth = !isAuth && (props.product === SYNASTRY ? props.step === 3 : props.step === 2)
  const index = props.step - 1
  const isFirstPartner = props.step === 1
  const isSecondPartner = props.step === 2
  const partner = store.partner.partner || getPartnerLS() || undefined

  const QUERY_PRODUCT = 'query-product'
  const sp = searchParams()
  const hasQueryProduct = sp.has(QUERY_PRODUCT)

  console.log('profile -', {...profile.birth})

  const titles: ITitles = {
    SYNASTRY: {
      new: `Введите данные ${isFirstPartner ? 'первого' : 'второго'} партнера`,
      edit: `Партнёр ${isFirstPartner ? '1' : '2'}`,
    },
    EMOTIONS: {
      new: 'Заполните данные ниже',
      edit: 'Ваши данные',
    },
    SOLAR: {
      new: 'Расчет солярного дня',
      edit: 'Расчет солярного дня',
    },
  }

  const getData = (product: string) => {
    return (store as any)[product.toLowerCase()][product === SYNASTRY ? 'getPartner' : 'getPerson']?.(index); // store.synastry.getPartner(index)
  }

  useEffect(() => {
    
    if (isNeedAuth) return;

    const data = getData(props.product);

    setFormData({
      name: data?.name ?? '',
      dateTime: data.dateTime ?? dayjs().add(dayjs().utcOffset(), 'minute').toISOString(),
      place: data?.place,
      location: data?.location
    })

    if (!props.edit) {
      if (isFirstPartner) gtmSend({
        'event': `${productsWindowData[props.product].gtm}_open-${props.product === SYNASTRY ? 'first-' : ''}partner`,
        'user_id': userId ? userId : ''
      })
    }

  }, [props.step]);

  useEffect(() => {
    if (hasQueryProduct) {
      const productCode = sp.get(QUERY_PRODUCT)
      store.products.getProducts()
        .then(res => {
          res.forEach(item => {
            if (item.code === productCode) {
              store.payments.setToken(item.id)
              setProductTokenLS(item.id.toString())
              store.products.setProductData(item)
              setProductDataLS(item)
              store.promocode.resetPromocode()
            }
          })
        })
    }
  }, [])

  const useProfileData = () => {
    setFormData({
      name: profile.firstName,
      dateTime:profile.birth.dateTime!,
      place: profile.birth.place!,
    })

    gtmSend({event: `${productsWindowData[props.product].gtm}_added-from-profile`, 'user_id': userId ? userId : ''})
  }

  const setData = (product: string, payload: any) => {
    const {index, personData} = payload

    switch (product) {
      case SYNASTRY:
        store.synastry.setPartner(index, personData)
        break
      case EMOTIONS:
        store.emotions.setPerson(personData)
        break
      case SOLAR:
        store.solar.setPerson(personData)
        break
    }
  }

  const confirmHandler = (formData: IPersonalForm) => {

    const personData = {
      name: formData.name,
      dateTime: dayjs.utc(formData.dateTime).toISOString(),
      place: formData.place,
      location: formData.location
    }

    setData(props.product, { index, personData });

    ////////
    if (!isAuth && ((props.product === SYNASTRY && props.step === 2) || (props.product !== SYNASTRY && props.step === 1))) {
      openSmsRegistrationWindow({
        onSuccess: () => {
          props.setStep(props.step + 1);
        }
      })
      return;
    }
    /////

    if (props.edit) {
      props.setStep(props.lastStep);
      props.setEdit(false);
      return;
    }

    if (!props.edit) {
      if (isFirstPartner) gtmSend({'event': `${productsWindowData[props.product].gtm}_done-${props.product === SYNASTRY ? 'first-partner' : 'info-profile'}`, 'user_id': userId ? userId : ''})
      if (isSecondPartner) gtmSend({'event': 'sinastry_done-second-partner', 'user_id': userId ? userId : ''})
    }

    props.setStep(props.step + 1);

  };

  const closeEditForm = async () => {
    props.setEdit(false);
    props.setStep(props.lastStep);
  };

  const setEmail = async (value: string) => {
    let person;
    switch (props.product) {
      case SYNASTRY:
        person = store.synastry.partners[0]
        break
      case EMOTIONS:
        person = store.emotions.person
        break
    }

    store.payments.setEmail(value)
    setLoading(true)

    try {
      await store.events.registration(value, 'ru', person?.name!, {
        dateTime: {
          date: dayjs.utc(person?.dateTime).format('DD.MM.YYYY'),
          time: dayjs.utc(person?.dateTime).format('HH:mm:ss')
        },
        place: person?.place
      },
      partner)
    } catch {
      props.setStep(props.step + 1)
    }

    setLoading(false)
    gtmSend({'event': `indi_enter_email_done_${productsWindowData[props.product].gtm}`, 'user_id': userId ? userId : ''})
  }

  // if (loading) {
  //   return <Loader />
  // }

  // if (isNeedAuth) {
  //   return <Container>
  //     <Header>
  //       <Title center={true}>Пожалуйста, авторизуйтесь</Title>
  //     </Header>
  //   </Container>
  // }

  return <Container>
    {props.edit
      ? <Header>
          <Title center={props.edit}>{titles[props.product as keyof ITitles].edit}</Title>
          {/* <CloseIcon onClick={closeEditForm}/> */}
        </Header>
      : <Header>
          <Title center={props.edit}>{titles[props.product as keyof ITitles].new}</Title>

          { hasProfileData &&
              <CustomButton
                onClick={useProfileData}
                color={'transparentWithBorder'}
              >
                Ввести свои данные из аккаунта
              </CustomButton>
          }

          {/* {disabledUseProfileData && <Info>У вас еще нет данных в аккаунте. Заполните данные в первый раз, и мы их сохраним.</Info>} */}
      </Header>}

    <PersonalForm 
      data={formData} 
      isEdit={props.edit} 
      submitTitle={'Продолжить'} 
      onSubmit={confirmHandler} 
      product={props.product}
      originDateTime={profile?.originalDate ? dayjs(profile?.originalDate, 'DD.MM.YYYY').toISOString() : ''}
    />
  </Container>
});

const Container = styled.main`
  margin-bottom: 1rem;
`;

const Header = styled.div`
  position: relative;
  margin-bottom: 2rem;

  svg {
    width: 1.5rem;
    position: absolute;
    top: 20%;
    right: 0;
    cursor: pointer;
  }
`

const Title = styled.div<{ center?: boolean }>`
  color: var(--text-primary);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  margin-bottom: 1rem;

  ${props => props.center && css`
    text-align: center;
  `}
`;

const Info = styled.div`
  color: var(--text-third);
  margin-top: 0.5rem;
`

const CustomButton = styled(Button)`
  padding: 1rem 0.8rem;
  width: 100%;
  font-weight: 500;
  border-radius: 0.5rem;
`
