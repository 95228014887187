import { ReactNode } from "react"
import { TButtonColor } from "src/components/ui/Button/Button"
import Button from "src/components/ui/legaсy/Button"
import styled, { css } from "styled-components"

interface BannerProps {
	className?: string
	background?: string
	backgroundImg?: string
	title: string
	text?: string
	date?: string
	buttonContent?: ReactNode
	buttonColor?: TButtonColor
	onClick?(): void
  label?: string
}

export const Banner = (props: BannerProps) => {

	return (
		<Container
      className={props.className}
      background={props.background}
      backgroundImg={props.backgroundImg}
    >
      {props.label && <div className='label'>{props.label}</div>}
			<div className='title'>{props.title}</div>
			{props.text && <div className='text'>{props.text}</div>}
			{props.date && <div className='date'>{props.date}</div>}
			{
				props.buttonContent && props.onClick &&
					<StyledButton className={'banner_button'}
											  onClick={props.onClick}
											  color={props.buttonColor}>
					  <div className='btn_content'>{props.buttonContent}</div>
					</StyledButton>
		  }
		</Container>
	)
}

const Container = styled.div<{background?: string, backgroundImg?: string}>`
	background: ${p => p.background ? p.background : '#0000000A'};
	border-radius: 1rem;
	padding: 1rem;
	color: var(--text-primary-white);

  ${p => p.backgroundImg && css`
    background: url(${p.backgroundImg}) 0 0/cover no-repeat;
  `};

  .label {
    margin-bottom: 0.5rem;
  }

	.title {
		font-size: 1.25rem;
		font-weight: 500;
		width: 70%;
	}

	.text {
		margin-top: 0.5rem;
		font-size: 0.875rem;
	}

	.date {
		margin-top: 0.5rem;
		font-size: 0.875rem;
	}

	@media (max-width: 800px) and (min-width: 400px)  {
    max-width: 430px;
	}

	@media (max-width: 390px) {
		.title {
			font-size: 1.125rem;
		}

		.text.date {
			margin-top: 0.35rem;
			font-size: 0.75rem;
		}
	}
`

const StyledButton = styled(Button)`
	padding: 0.5rem 0.75rem;
	margin-top: 1rem;
	width: 45%;

	.btn_content {
		white-space: nowrap;
		font-weight: 500;
		font-size: 0.75rem;
	}

	svg {
		width: 0.75rem;
		height: 0.75rem;
	}

	@media (max-width: 390px) {
		width: 50%;

		.btn_content {
			font-size: 0.7rem;
		}
	}
`
