import { useEffect, useState } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { isMobile } from "react-device-detect"

import { Input } from "src/libs"
import { AlertTriangle } from "../../assets/icons/system/system-icons"
import { FlexContainer } from "../../components/layout/elements"
import Overlay from "../../components/Overlay"
import Policy from "../../components/Policy"
import { Button, Radius } from "../../components/ui/Button/Button"
import store from "../../store/Store"
import { TrialClose } from "../paywall/components/CloseIcon"
import { gtmSend } from "../../helpers/url"

interface PromocodeModalProps {
  onClose(): void
  showToaster(): void
}

export const PromocodeModal = observer((props: PromocodeModalProps) => {
  const {
    onClose,
    showToaster,
  } = props

  const promoData = store.promocode.promoData
  const promoError = store.promocode.promoError
  const userId = store.sessionData?.id

  const [promocode, setPromocode] = useState(promoData?.title ?? '')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    setError(promoError)
  }, [promoError])

  const onChangeHandler = (value: string) => {
    error && setError('')
    setPromocode(value)
  }

  const onClickApplyButton = async () => {
    if (!promocode) {
      setError('Введите промокод')
      return
    }

    if (promoData) {
      store.promocode.resetPromocode()
    } else {
      setError('')
      await store.promocode.checkPromocode(promocode, undefined, true)
    }
  }

  const getDiscount = () => {
    switch(promoData?.actions.subscription) {
      case 1:
        return '-499 ₽'
      case 2:
        return '-2 399 ₽'
      default:
        return ''
    }
  }

  const getPrice = () => {
    switch(promoData?.actions.subscription) {
      case 1:
        return '0 ₽ за 1 месяц ₽'
      case 2:
        return '0 ₽ за 6 месяцев'
      default:
        return ''
    }
  }

  const getButtonTitle = () => {
    let period = ''

    switch(promoData?.actions.subscription) {
      case 1:
        period = '1 месяц'
        break
      case 2:
        period = '6 месяцев'
        break
    }

    return `Начать ${period} бесплатно`
  }

  const getPolicyTitle = () => {
    return `Нажимая кнопку «${getButtonTitle()}», вы соглашаетесь с `
  }

  const usePromocode = async () => {
    if (promocode && !promoError) {
      await store.promocode.usePromocode(promocode)
        .then(() => {
          onClose()
          showToaster()
          gtmSend({'event': 'indi_profile_promo_done', 'user_id': userId})
        })
    }
  }

  return (
    <Overlay onClick={onClose}>
      <Modal isMobile={isMobile}>
        <StyledTrialClose onClick={onClose} />
        <Title>Ввод промокода</Title>
        <InputContainer>
          <StyledInput
            value={promocode}
            onChange={onChangeHandler}
            autoFocus
          />
          <ApplyButton
            onClick={onClickApplyButton}
            color={promoData ? 'reset' : 'apply'}
            radius={Radius.RECTANGLE}
            disabled={!promocode}
          >
            {promoData ? 'Сбросить' : 'Применить'}
          </ApplyButton>
        </InputContainer>

        <ErrorContainer>
          {
            error && <>
              <AlertTriangle /> {error}
            </>
          }
        </ErrorContainer>

        {
          promoData && (
            <Total>
              <TotalItem>
                <p className={'title'}>Скидка</p>
                <p className={'value'}>{getDiscount()}</p>
              </TotalItem>

              <TotalItem>
                <p className={'title'}>К оплате сейчас</p>
                <p className={'value'}>{getPrice()}</p>
              </TotalItem>

              <StyledButton
                color={'green'}
                onClick={usePromocode}
              >
                {getButtonTitle()}
              </StyledButton>

              <Policy text={getPolicyTitle()}/>
            </Total>
          )
        }
      </Modal>
    </Overlay>
  )
})

const Modal = styled.div<{ isMobile: boolean }>`
  position: relative;
  width: 80%;
  background-color: white;
  padding: 1.5rem 1rem;
  box-shadow: 0px 3.2px 14px rgba(0, 0, 0, 0.06), 0px 0.6px 1.8px rgba(0, 0, 0, 0.04);
  border-radius: 1.5rem;
`

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
`

const InputContainer = styled(FlexContainer)`
  margin-top: 1rem;

  border: 1px solid #0000001A;
  border-radius: 0.875rem;
  padding: 0.75rem;
`

const StyledInput = styled(Input)`
  input {
    border: none;
    height: fit-content;
    padding: 0.5rem 0.75rem;
  }
`

const StyledTrialClose = styled(TrialClose)`
	top: 1.5rem;
`

const ErrorContainer = styled(FlexContainer)`
  min-height: 1.25rem;
  color: var(--color-red);
  align-items: center;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;

  > svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`

const ApplyButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: auto;
  height: auto;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
`

const Total = styled.div`
`

const TotalItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.5rem;

  .title {
    color: var(--text-secondary);
    font-size: 0.875rem;
  }

  .value {
    font-weight: 500;
  }
`

const StyledButton = styled(Button)`
  color: var(--text-primary);
  margin-top: 1rem;
`
